import { createContext, useState } from "react";

export const DisplayParametresContext = createContext()

export const DisplayParametresContextProvider = ({children}) => {
    const [displayParametres, setDisplayParametres] = useState(false)
    return(
        <DisplayParametresContext.Provider value={{displayParametres, setDisplayParametres}}>
            {children}
        </DisplayParametresContext.Provider>
    )
}