import { useContext, useEffect, useState } from "react";
import MonthlyPerformanceOKR from "../MonthlyPerformanceOKR";
import MonthlyPerformanceChart from "../monthlyPerformanceChart";
import { DisplayViewMonthlyPerformanceContext } from "../../utils/displayViewMonthlyPerformance";
import MonthlyPerformancesPanel from "../monthlyPerformancesPanel";
import { MonthlyPerformanceToSeeContext } from "../../utils/monthlyPerformanceToSee";

function ViewMonthlyPerformance() {
    const { setDisplayViewMonthlyPerformance } = useContext(DisplayViewMonthlyPerformanceContext);

    const now = new Date();
    const dayOfWeek = now.getDay();
    const distanceToMonday = (dayOfWeek + 6) % 7; 
    const startOfLastWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - distanceToMonday - 7);
    const endOfLastWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - distanceToMonday - 1, 23, 59, 59, 999);

    const [averageMonthlyPerformance, setAverageMonthlyPerformance] = useState({})

    const {setMonthlyPerformanceToSee} = useContext(MonthlyPerformanceToSeeContext)

    useEffect(()=>{
        async function GetMonthlyPerformance(){
            try {
                const token = localStorage.getItem("token");
                const response = await fetch(`https://wave-support.com/api/getlastweekaverageperformance`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    const responseData = await response.json();
                    setAverageMonthlyPerformance(responseData)
                } else {
                    console.error('Failed to fetch performances:', response.status);
                }
            } catch (err) {
                console.log(err);
            }
        }

        GetMonthlyPerformance();
    }, [setAverageMonthlyPerformance])

    function Refresh(){
        setMonthlyPerformanceToSee({
            user: {
                prenom: "",
                nom: localStorage.getItem("team")
            },
            performance: {
                avg_nbr_appels: averageMonthlyPerformance.nbr_appels,
                avg_nbr_appels_15s: averageMonthlyPerformance.nbr_appels_15s,
                avg_nbr_appels_4m: averageMonthlyPerformance.nbr_appels_4m,
                avg_connected_hours: averageMonthlyPerformance.connected_hours,
                avg_talking_time: averageMonthlyPerformance.talking_time,
                avg_callback: averageMonthlyPerformance.callback,
                avg_dmt: averageMonthlyPerformance.dmt,
                avg_ath: averageMonthlyPerformance.ath,
            }
        })
    }

    async function ExportMonthlyPerformancesToExcel() {
        try {
            const now = new Date();
            const dayOfWeek = now.getDay();
            const distanceToMonday = (dayOfWeek + 6) % 7; 
            const startOfLastWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - distanceToMonday - 7);
            const endOfLastWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - distanceToMonday - 1, 23, 59, 59, 999);
            
            const response = await fetch(`https://wave-support.com/api/exportmonthlyperformances`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) {
                throw new Error("Failed to export performances");
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `performances_du_${startOfLastWeek.toLocaleDateString()}_au_${endOfLastWeek.toLocaleDateString()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

        } catch (err) {
            console.error(err);
        }
    }

    return (
        <div className="view_performance">
            <div className="view_performance__stats">
                <div className="view_performance__refresh" onClick={()=>{Refresh()}}>
                    <i class="fa-solid fa-arrows-rotate"></i>
                </div>
                <div className="view_performance__export" onClick={()=>{ExportMonthlyPerformancesToExcel()}}>
                    <i class="fa-solid fa-file-arrow-down"></i>
                </div>
                <div className="view_performance__stats__diagram">
                    <MonthlyPerformanceChart averageMonthlyPerformance={averageMonthlyPerformance}/>
                </div>
                <MonthlyPerformanceOKR searchTerm={""} averageMonthlyPerformance={averageMonthlyPerformance}/>
            </div>
            <div className="view_performance__edit" style={{margin: "5px 0px"}}>
                <div className="view_performance__edit__reps_head" style={{fontSize: "16px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px"}}>
                    {`Performances du ${startOfLastWeek.toLocaleDateString()} au ${endOfLastWeek.toLocaleDateString()}`}
                </div>
                <MonthlyPerformancesPanel searchTerm={""}/>
            </div>
            <div className="view_performance__close" onClick={()=>{setDisplayViewMonthlyPerformance(false)}}><i class="fa-regular fa-circle-xmark"></i></div>
        </div>
    )
}

export default ViewMonthlyPerformance;
