import { createContext, useState } from "react";

export const DisplayViewPerformanceContext = createContext()

export const DisplayViewPerformanceContextProvider = ({children}) => {
    const [displayViewPerformance, setDisplayViewPerformance] = useState(false)
    return(
        <DisplayViewPerformanceContext.Provider value={{displayViewPerformance, setDisplayViewPerformance}}>
            {children}
        </DisplayViewPerformanceContext.Provider>
    )
}