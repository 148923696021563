import { useContext, useEffect, useState } from "react";
import PerformancesPanel from "../../components/performancesPanel";
import { SelectedDateContext } from "../../utils/selectedDate";
import AverageRepsPerformances from "../../components/averageRepsPerformances";
import AverageRepsPerformanceChart from "../../components/averageRepsPerformanceChart";
import RepsViewPerformance from "../../components/repsViewPerformance";
import ArrierePlan from "../../components/arrierePlan";
import { DisplayRepsViewPerformanceContext } from "../../utils/displayRepsViewPerformance";
import { useNavigate } from "react-router-dom";
import { DisplayParametresContext } from "../../utils/displayParametresContext";
import Parametres from "../../components/parametres";
import { DisplayViewMonthlyPerformanceContext } from "../../utils/displayViewMonthlyPerformance";
import ViewMonthlyPerformance from "../../components/ViewMonthlyPerformance";

function RepsDashbord() {
    const { selectedDate, setSelectedDate } = useContext(SelectedDateContext);

    const { displayRepsViewPerformance } = useContext(DisplayRepsViewPerformanceContext);

    const { displayViewMonthlyPerformance, setDisplayViewMonthlyPerformance } = useContext(DisplayViewMonthlyPerformanceContext);

    const { displayParametres, setDisplayParametres } = useContext(DisplayParametresContext);

    const [searchTerm, setSearchTerm] = useState("");

    function getYesterdayDate() {
        const today = new Date();
        today.setDate(today.getDate() - 1);

        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
        const day = String(today.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    function handleSearch(event) {
        setSearchTerm(event.target.value);
    }

    const navigate = useNavigate();

    useEffect(()=>{
        const token = localStorage.getItem("token")
        if(!token){
            navigate("/login")
        }
    }, [navigate])

    function Logout(){
        localStorage.removeItem("token")
        localStorage.removeItem("username")
        localStorage.removeItem("role")
        localStorage.removeItem("team")
        navigate("/login")
    }

    return (
        <div className="admin_dashboard">
            <div className="admin_dashboard__main_bar">
                <div className="admin_dashboard__main_bar__logo">
                    <img src="/images/wave-logo-2.jpg" alt="" width={50} />
                </div>
                <div className="admin_dashboard__main_bar__items">
                    <div className="admin_dashboard__main_bar__items__btn" onClick={()=>{ navigate("/reps-dashboard") }}><i className="fa-solid fa-house"></i></div>
                    <div className="admin_dashboard__main_bar__items__btn" onClick={()=>{ setDisplayViewMonthlyPerformance(true) }}><i class="fa-solid fa-calendar-days"></i></div>
                    <div className="admin_dashboard__main_bar__items__btn" onClick={()=>{ Logout() }}><i class="fa-solid fa-right-from-bracket"></i></div>
                </div>
                <div className="admin_dashboard__main_bar__profil" onClick={()=>{ setDisplayParametres(true) }}>
                    {localStorage.getItem("username").charAt(0)}
                </div>
            </div>
            <div className="admin_dashboard__container">
                <div className="admin_dashboard__container__stats">
                    <div className="admin_dashboard__container__stats__diagram">
                        <div className="admin_dashboard__container__stats__diagram__head">
                            <div className="admin_dashboard__container__stats__diagram__head__title">
                                Ma performance | <span style={{color: "#1dc4ff"}}>{localStorage.getItem("username")}</span>
                            </div>
                            <div className="admin_dashboard__container__stats__diagram__head__date">
                                <input type="date" max={getYesterdayDate()} value={selectedDate} onChange={(e) => { setSelectedDate(e.target.value) }} />
                            </div>
                        </div>
                        <div className="admin_dashboard__container__stats__diagram__container">
                            <AverageRepsPerformanceChart />
                        </div>
                    </div>
                    <AverageRepsPerformances />
                </div>
                <div className="admin_dashboard__container__reps_list">
                    <div className="admin_dashboard__container__reps_list__head">
                        <div className="admin_dashboard__container__reps_list__head__title">
                            <div><i className="fa-solid fa-people-group"></i></div> <div>{localStorage.getItem("team")}</div>
                        </div>
                        <div className="admin_dashboard__container__reps_list__head__search_bar">
                            <input type="text" placeholder="Rechercher un membre..." autoComplete="off" value={searchTerm} onChange={handleSearch} /> <i className="fa-solid fa-magnifying-glass"></i>
                        </div>
                    </div>
                    <PerformancesPanel searchTerm={searchTerm} />
                </div>
            </div>
            {displayRepsViewPerformance && <RepsViewPerformance />}
            {displayRepsViewPerformance && <ArrierePlan />}

            {displayViewMonthlyPerformance && <ViewMonthlyPerformance />}
            {displayViewMonthlyPerformance && <ArrierePlan />}

            {displayParametres && <Parametres />}
            {displayParametres && <ArrierePlan />}
        </div>
    );
}

export default RepsDashbord;
