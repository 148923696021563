import { createContext, useState } from "react";

export const PerformanceToSeeContext = createContext()

export const PerformanceToSeeContextProvider = ({children}) => {
    const [performanceToSee, setPerformanceToSee] = useState({})
    return(
        <PerformanceToSeeContext.Provider value={{performanceToSee, setPerformanceToSee}}>
            {children}
        </PerformanceToSeeContext.Provider>
    )
}