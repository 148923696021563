import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "../src/style.css"
import AdminDashboard from './pages/adminDashboard';
import RepsDashboard from './pages/repsDashboard';
import { DisplayPerformanceAddPanelContextProvider } from './utils/displayPerformanceAddPanelContext';
import { UpdatePerformancesContextProvider } from './utils/updatePerformances';
import { SelectedDateContextProvider } from './utils/selectedDate';
import RepsDashbord from './pages/repsDashboard';
import { DisplayViewPerformanceContextProvider } from './utils/displayViewPerformance';
import { PerformanceToSeeContextProvider } from './utils/performanceToSee';
import { DisplayRepsViewPerformanceContextProvider } from './utils/displayRepsViewPerformance';
import { DisplayUserAddPanelContextProvider } from './utils/displayUserAddPanelContext';
import { DisplayParametresContextProvider } from './utils/displayParametresContext';
import { DisplayViewMonthlyPerformanceContextProvider } from './utils/displayViewMonthlyPerformance';
import { MonthlyPerformanceToSeeContextProvider } from './utils/monthlyPerformanceToSee';
import { DisplayExportViewContextProvider } from './utils/displayExportViewContext';
import { DisplayImportViewContextProvider } from './utils/displayImportViewContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SelectedDateContextProvider>
    <DisplayParametresContextProvider>
    <DisplayExportViewContextProvider>
    <DisplayImportViewContextProvider>
    <UpdatePerformancesContextProvider>
    <DisplayPerformanceAddPanelContextProvider>
    <DisplayUserAddPanelContextProvider>
    <DisplayViewPerformanceContextProvider>
    <DisplayViewMonthlyPerformanceContextProvider>
    <DisplayRepsViewPerformanceContextProvider>
    <PerformanceToSeeContextProvider>
    <MonthlyPerformanceToSeeContextProvider>
    <Router>
      <Routes>
        <Route path='/' element={<App />}/>
        <Route path='/login' element={<App />}/>
        <Route path='/grouplead-dashboard' element={<AdminDashboard />}/>
        <Route path='/reps-dashboard' element={<RepsDashbord />}/>
        <Route path='/reps-dashboard' element={<RepsDashboard />}/>
      </Routes>
    </Router>
    </MonthlyPerformanceToSeeContextProvider>
    </PerformanceToSeeContextProvider>
    </DisplayRepsViewPerformanceContextProvider>
    </DisplayViewMonthlyPerformanceContextProvider>
    </DisplayViewPerformanceContextProvider>
    </DisplayUserAddPanelContextProvider>
    </DisplayPerformanceAddPanelContextProvider>
    </UpdatePerformancesContextProvider>
    </DisplayImportViewContextProvider>
    </DisplayExportViewContextProvider>
    </DisplayParametresContextProvider>
    </SelectedDateContextProvider>
  </React.StrictMode>
)
