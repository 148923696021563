import { useContext } from "react";
import { PerformanceToSeeContext } from "../../utils/performanceToSee";

function PerformanceOKR() {
    const { performanceToSee } = useContext(PerformanceToSeeContext);

    if (!performanceToSee) {
        return <div>Loading...</div>;
    }

    return (
        <div className="view_performance__stats__okrs">
            <div className={`view_performance__stats__okrs__stat ${performanceToSee.nbr_appels >= 240 ? "stat__green" : "stat__red"}`}>
                <div className="view_performance__stats__okrs__stat__head">
                    <div className="view_performance__stats__okrs__stat__head__item">
                        Appels traités
                    </div>
                    <div className="view_performance__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-phone"></i>
                    </div>
                </div>
                <div className="view_performance__stats__okrs__stat__data">{performanceToSee.nbr_appels || 0} A</div>
            </div>
            <div className={`view_performance__stats__okrs__stat ${performanceToSee.connected_hours >= 8 ? "stat__green" : "stat__red"}`}>
                <div className="view_performance__stats__okrs__stat__head">
                    <div className="view_performance__stats__okrs__stat__head__item">
                        {"H.C"}
                    </div>
                    <div className="view_performance__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-hourglass"></i>
                    </div>
                </div>
                <div className="view_performance__stats__okrs__stat__data">{performanceToSee.connected_hours || 0} H</div>
            </div>
            <div className={`view_performance__stats__okrs__stat ${Math.round(performanceToSee.nbr_appels / performanceToSee.connected_hours) >= 30 ? "stat__green" : "stat__red"}`}>
                <div className="view_performance__stats__okrs__stat__head">
                    <div className="view_performance__stats__okrs__stat__head__item">
                        ATH
                    </div>
                    <div className="view_performance__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-percent"></i>
                    </div>
                </div>
                <div className="view_performance__stats__okrs__stat__data">{Math.round(performanceToSee.nbr_appels / performanceToSee.connected_hours) || 0} A / H</div>
            </div>
            <div className={`view_performance__stats__okrs__stat ${performanceToSee.dmt <= 1.9 ? "stat__green" : "stat__red"}`}>
                <div className="view_performance__stats__okrs__stat__head">
                    <div className="view_performance__stats__okrs__stat__head__item">
                        DMT
                    </div>
                    <div className="view_performance__stats__okrs__stat__head__icon">
                        <i className="fa-regular fa-clock"></i>
                    </div>
                </div>
                <div className="view_performance__stats__okrs__stat__data">{performanceToSee.dmt || 0} mn</div>
            </div>
            <div className={`view_performance__stats__okrs__stat ${performanceToSee.callback <= 15 ? "stat__green" : "stat__red"}`}>
                <div className="view_performance__stats__okrs__stat__head">
                    <div className="view_performance__stats__okrs__stat__head__item">
                        Callback
                    </div>
                    <div className="view_performance__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-arrow-rotate-left"></i>
                    </div>
                </div>
                <div className="view_performance__stats__okrs__stat__data">{performanceToSee.callback || 0} %</div>
            </div>
            <div className={`view_performance__stats__okrs__stat ${performanceToSee.talking_time >= 85 ? "stat__green" : "stat__red"}`}>
                <div className="view_performance__stats__okrs__stat__head">
                    <div className="view_performance__stats__okrs__stat__head__item">
                        {"T.T"}
                    </div>
                    <div className="view_performance__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-walkie-talkie"></i>
                    </div>
                </div>
                <div className="view_performance__stats__okrs__stat__data">{performanceToSee.talking_time || 0} %</div>
            </div>
            <div className={`view_performance__stats__okrs__stat ${parseInt(performanceToSee.nbr_appels_4m * 100 / performanceToSee.nbr_appels) <= 3 ? "stat__green" : "stat__red"}`}>
                <div className="view_performance__stats__okrs__stat__head">
                    <div className="view_performance__stats__okrs__stat__head__item">
                        {"Appels > 4 mn"}
                    </div>
                    <div className="view_performance__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-phone-volume"></i>
                    </div>
                </div>
                <div className="view_performance__stats__okrs__stat__data">{parseInt(performanceToSee.nbr_appels_4m * 100 / performanceToSee.nbr_appels) || 0} %</div>
            </div>
            <div className={`view_performance__stats__okrs__stat ${parseInt(performanceToSee.nbr_appels_15s * 100 / performanceToSee.nbr_appels) <= 1 ? "stat__green" : "stat__red"}`}>
                <div className="view_performance__stats__okrs__stat__head">
                    <div className="view_performance__stats__okrs__stat__head__item">
                        {"Appels < 15 s"}
                    </div>
                    <div className="view_performance__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-phone-slash"></i>
                    </div>
                </div>
                <div className="view_performance__stats__okrs__stat__data">{parseInt(performanceToSee.nbr_appels_15s * 100 / performanceToSee.nbr_appels) || 0} %</div>
            </div>
        </div>
    );
}

export default PerformanceOKR;
