import { useState, useContext } from "react";
import { SelectedDateContext } from "../../utils/selectedDate";
import { DisplayExportViewContext } from "../../utils/displayExportViewContext";

function ExportView() {
    const { selectedDate } = useContext(SelectedDateContext);
    const { setDisplayExportView } = useContext(DisplayExportViewContext);
    const [resultMessage, setResultMessage] = useState("");
    const [loading, setLoading] = useState(false);  // État pour le chargement

    async function ExportDailyPerformancesToExcel() {
        setLoading(true);  // Démarrer le chargement
        setResultMessage("");  // Réinitialiser le message de résultat

        try {
            const response = await fetch(`https://wave-support.com/api/exportdailyperformances?date=${selectedDate}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) {
                throw new Error("Failed to export performances");
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `performances_${new Date(selectedDate).toLocaleDateString()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            // Update result message
            setResultMessage("Exportation réussie !");
        } catch (err) {
            console.error(err);
            // Update result message on error
            setResultMessage("Erreur lors de l'exportation.");
        } finally {
            setLoading(false);  // Finir le chargement
        }
    }

    return (
        <div className="export_view">
            <div className="export_view__text">
                Vous allez exporter les performances du groupe à la date suivante : <span style={{ color: "#f67a00" }}>{new Date(selectedDate).toLocaleDateString()}</span>
            </div>
            <div className="export_view__btns">
                <div className="export_view__btns__close" onClick={() => { setDisplayExportView(false) }}>
                    Fermer
                </div>
                <div className="export_view__btns__export" onClick={ExportDailyPerformancesToExcel}>
                    Exporter
                </div>
            </div>
            <div className="export_view__result">
                {loading && <p>Chargement en cours...</p>} {/* Afficher le message de chargement */}
                {resultMessage && <p>{resultMessage}</p>} {/* Afficher le message de résultat */}
            </div>
        </div>
    );
}

export default ExportView;
