import { createContext, useState } from "react";

export const DisplayRepsViewPerformanceContext = createContext()

export const DisplayRepsViewPerformanceContextProvider = ({children}) => {
    const [displayRepsViewPerformance, setDisplayRepsViewPerformance] = useState(false)
    return(
        <DisplayRepsViewPerformanceContext.Provider value={{displayRepsViewPerformance, setDisplayRepsViewPerformance}}>
            {children}
        </DisplayRepsViewPerformanceContext.Provider>
    )
}