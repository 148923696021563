import { useContext, useEffect, useState } from "react";
import { SelectedDateContext } from "../../utils/selectedDate";
import { UpdatePerformancesContext } from "../../utils/updatePerformances";

function AveragePerformances(){

    const { selectedDate } = useContext(SelectedDateContext);

    const {updatePerformances} = useContext(UpdatePerformancesContext)

    const [averagePerformances, setAveragePerformances] = useState({})

    useEffect(() => {
        async function GetAveragePerformances() {
            try {
                const token = localStorage.getItem("token");
                const response = await fetch(`https://wave-support.com/api/getaverageperformances?date=${selectedDate}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    const responseData = await response.json();
                    setAveragePerformances(responseData)
                } else {
                    console.error('Failed to fetch performances:', response.status);
                }
            } catch (err) {
                console.log(err);
            }
        }
        GetAveragePerformances();
    }, [selectedDate, updatePerformances]);


    return(
        <div className="admin_dashboard__container__stats__okrs">
            <div className={`admin_dashboard__container__stats__okrs__stat ${averagePerformances.nbr_appels >= 240 ? "stat__green" : "stat__red"}`}>
                <div className="admin_dashboard__container__stats__okrs__stat__head">
                    <div className="admin_dashboard__container__stats__okrs__stat__head__item">
                        Appels traités
                    </div>
                    <div className="admin_dashboard__container__stats__okrs__stat__head__icon">
                        <i class="fa-solid fa-phone"></i>
                    </div>
                </div>
                <div className="admin_dashboard__container__stats__okrs__stat__data">{averagePerformances.nbr_appels ? averagePerformances.nbr_appels : 0} A</div>
            </div>
            <div className={`admin_dashboard__container__stats__okrs__stat ${(averagePerformances.connected_hours) >= 8 ? "stat__green" : "stat__red"}`}>
                <div className="admin_dashboard__container__stats__okrs__stat__head">
                    <div className="admin_dashboard__container__stats__okrs__stat__head__item">
                        {"H.C"}
                    </div>
                    <div className="admin_dashboard__container__stats__okrs__stat__head__icon">
                        <i class="fa-solid fa-hourglass"></i>
                    </div>
                </div>
                <div className="admin_dashboard__container__stats__okrs__stat__data">{averagePerformances.connected_hours ? Number(averagePerformances.connected_hours).toFixed(1) : 0} H</div>
            </div>
            <div className={`admin_dashboard__container__stats__okrs__stat ${averagePerformances.ath >= 30 ? "stat__green" : "stat__red"}`}>
                <div className="admin_dashboard__container__stats__okrs__stat__head">
                    <div className="admin_dashboard__container__stats__okrs__stat__head__item">
                        ATH
                    </div>
                    <div className="admin_dashboard__container__stats__okrs__stat__head__icon">
                        <i class="fa-solid fa-percent"></i>
                    </div>
                </div>
                <div className="admin_dashboard__container__stats__okrs__stat__data">{averagePerformances.ath ? averagePerformances.ath : 0} A / H</div>
            </div>
            <div className={`admin_dashboard__container__stats__okrs__stat ${averagePerformances.dmt <= 1.9 ? "stat__green" : "stat__red"}`}>
                <div className="admin_dashboard__container__stats__okrs__stat__head">
                    <div className="admin_dashboard__container__stats__okrs__stat__head__item">
                        DMT
                    </div>
                    <div className="admin_dashboard__container__stats__okrs__stat__head__icon">
                        <i class="fa-regular fa-clock"></i>
                    </div>
                </div>
                <div className="admin_dashboard__container__stats__okrs__stat__data">{averagePerformances.dmt ? averagePerformances.dmt : 0} mn</div>
            </div>
            <div className={`admin_dashboard__container__stats__okrs__stat ${
                averagePerformances.callback <= 15 
                    ? "stat__green" 
                    : averagePerformances.callback <= 17 
                    ? "stat__yellow" 
                    : "stat__red"
                }`} style={{ marginTop: "0px" }}>
                <div className="admin_dashboard__container__stats__okrs__stat__head">
                    <div className="admin_dashboard__container__stats__okrs__stat__head__item">
                        Callback
                    </div>
                    <div className="admin_dashboard__container__stats__okrs__stat__head__icon">
                        <i class="fa-solid fa-arrow-rotate-left"></i>
                    </div>
                </div>
                <div className="admin_dashboard__container__stats__okrs__stat__data">
                    {averagePerformances.callback ? averagePerformances.callback : 0} %
                </div>
            </div>
            
            <div className={`admin_dashboard__container__stats__okrs__stat ${
                averagePerformances.talking_time >= 85 
                    ? "stat__green" 
                    : averagePerformances.talking_time >= 83 
                    ? "stat__yellow" 
                    : "stat__red"
            }`} style={{ marginTop: "0px" }}>
                <div className="admin_dashboard__container__stats__okrs__stat__head">
                    <div className="admin_dashboard__container__stats__okrs__stat__head__item">
                        {"T.T"}
                    </div>
                    <div className="admin_dashboard__container__stats__okrs__stat__head__icon">
                        <i class="fa-solid fa-walkie-talkie"></i>
                    </div>
                </div>
                <div className="admin_dashboard__container__stats__okrs__stat__data">
                    {averagePerformances.talking_time ? averagePerformances.talking_time : 0} %
                </div>
            </div>

            <div className={`admin_dashboard__container__stats__okrs__stat ${Math.round(averagePerformances.nbr_appels_4m * 100 / averagePerformances.nbr_appels) <= 3 ? "stat__green" : "stat__red"}`} style={{marginTop: "0px"}}>
                <div className="admin_dashboard__container__stats__okrs__stat__head">
                    <div className="admin_dashboard__container__stats__okrs__stat__head__item">
                        {"Appels > 4 mn"}
                    </div>
                    <div className="admin_dashboard__container__stats__okrs__stat__head__icon">
                        <i class="fa-solid fa-phone-volume"></i>
                    </div>
                </div>
                <div className="admin_dashboard__container__stats__okrs__stat__data">{ averagePerformances.nbr_appels_4m ? Math.round(averagePerformances.nbr_appels_4m * 100 / averagePerformances.nbr_appels) : 0} %</div>
            </div>
            <div className={`admin_dashboard__container__stats__okrs__stat ${Math.round(averagePerformances.nbr_appels_15s * 100 / averagePerformances.nbr_appels) <= 1 ? "stat__green" : "stat__red"}`} style={{marginTop: "0px"}}>
                <div className="admin_dashboard__container__stats__okrs__stat__head">
                    <div className="admin_dashboard__container__stats__okrs__stat__head__item">
                        {"Appels < 15 s"}
                    </div>
                    <div className="admin_dashboard__container__stats__okrs__stat__head__icon">
                        <i class="fa-solid fa-phone-slash"></i>
                    </div>
                </div>
                <div className="admin_dashboard__container__stats__okrs__stat__data">{averagePerformances.nbr_appels_15s ? Math.round(averagePerformances.nbr_appels_15s * 100 / averagePerformances.nbr_appels) : 0} %</div>
            </div>
        </div>
    )
}

export default AveragePerformances