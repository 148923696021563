import React, { useContext } from "react";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { PerformanceToSeeContext } from "../../utils/performanceToSee";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    Filler
);

function PerformanceChart() {

    const { performanceToSee } = useContext(PerformanceToSeeContext);

    const perfectPerformance = {
        nbr_appels: 240,
        nbr_appels_15s: 1,
        nbr_appels_4m: 3,
        connected_hours: 8,
        talking_time: 85,
        callback: 15,
        dmt: 1.9,
        ath: 30,
    };

    if (!performanceToSee) {
        return <div>Loading...</div>;
    }

    const data = {
        labels: [
            'Number of Calls', 'Calls < 15s', 'Calls > 4m', 
            'Connected Hours', 'Talking Time', 'Callback', 'DMT', 'ATH'
        ],
        datasets: [
            {
                label: `Performance de ${performanceToSee.user.prenom} ${performanceToSee.user.nom}`,
                data: [
                    performanceToSee.nbr_appels,
                    performanceToSee.nbr_appels_15s,
                    performanceToSee.nbr_appels_4m,
                    performanceToSee.connected_hours,
                    performanceToSee.talking_time,
                    performanceToSee.callback,
                    performanceToSee.dmt,
                    Math.round(performanceToSee.nbr_appels / performanceToSee.connected_hours) || performanceToSee.ath
                ],
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                fill: true,
            },
            {
                label: 'Performance attendue',
                data: [
                    perfectPerformance.nbr_appels,
                    perfectPerformance.nbr_appels_15s,
                    perfectPerformance.nbr_appels_4m,
                    perfectPerformance.connected_hours,
                    perfectPerformance.talking_time,
                    perfectPerformance.callback,
                    perfectPerformance.dmt,
                    perfectPerformance.ath
                ],
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
                borderDash: [5, 5],
                fill: true,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `Performance de ${performanceToSee.user.prenom} ${performanceToSee.user.nom}`,
            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true,
                    },
                    drag: {
                        enabled: true,
                    },
                    mode: 'xy',
                },
                pan: {
                    enabled: true,
                    mode: 'xy',
                }
            }
        },
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
            }
        }
    };

    return <Line data={data} options={options} />;
}

export default PerformanceChart;
