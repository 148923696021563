import { createContext, useState } from "react";

export const DisplayViewMonthlyPerformanceContext = createContext()

export const DisplayViewMonthlyPerformanceContextProvider = ({children}) => {
    const [displayViewMonthlyPerformance, setDisplayViewMonthlyPerformance] = useState(false)
    return(
        <DisplayViewMonthlyPerformanceContext.Provider value={{displayViewMonthlyPerformance, setDisplayViewMonthlyPerformance}}>
            {children}
        </DisplayViewMonthlyPerformanceContext.Provider>
    )
}