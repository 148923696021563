import { useContext, useState } from "react";
import { DisplayImportViewContext } from "../../utils/displayImportViewContext";
import { UpdatePerformancesContext } from "../../utils/updatePerformances";

function ImportView() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedDate, setSelectedDate] = useState("");

    const { setDisplayImportView } = useContext(DisplayImportViewContext);

    const {updatePerformances, setUpdatePerformances} = useContext(UpdatePerformancesContext)

    async function ImportPerformancesViaExcelFile() {
        if (!selectedFile || !selectedDate) {
            alert("Veuillez sélectionner un fichier et une date.");
            return;
        }

        try {
            const formData = new FormData();
            formData.append("file", selectedFile);
            formData.append("date", selectedDate);

            const token = localStorage.getItem("token");

            const response = await fetch("https://wave-support.com/api/importperformances", {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });

            if (response.status === 200) {
                setUpdatePerformances(!updatePerformances)
                alert("Les performances ont été importées avec succès.");
            } else {
                alert("Une erreur est survenue lors de l'importation.");
            }
        } catch (err) {
            console.log(err);
            alert("Erreur lors de l'importation des performances.");
        }
    }

    function handleFileChange(event) {
        setSelectedFile(event.target.files[0]);
    }

    function handleDateChange(event) {
        setSelectedDate(event.target.value);
    }

    function getYesterdayDate() {
        const today = new Date();
        today.setDate(today.getDate() - 1);

        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
        const day = String(today.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    return (
        <div className="import-view">
            <div className="import-view__text">
                Pour uploader vos performances, veuillez sélectionner la date et indiquer le fichier à extraire.
            </div>
            <div className="import-view__date">
                <input type="date" max={getYesterdayDate()} onChange={handleDateChange} />
            </div>
            <div className="import-view__file">
                <input type="file" onChange={handleFileChange} />
            </div>
            <div className="import-view__btns">
                <div className="import-view__btns__item" onClick={()=>{setDisplayImportView()}}>
                    Fermer
                </div>
                <div className="import-view__btns__item" onClick={()=>{ImportPerformancesViaExcelFile()}}>
                    Importer
                </div>
            </div>
        </div>
    );
}

export default ImportView;
