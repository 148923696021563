import { useContext, useEffect, useState } from "react"
import { DisplayPerformanceAddPanelContext } from "../../utils/displayPerformanceAddPanelContext"
import { UpdatePerformancesContext } from "../../utils/updatePerformances"

function PerformanceAddPanel() {

    const [allUsers, setAllUsers] = useState([])

    const {setDisplayPerformanceAddPanel} = useContext(DisplayPerformanceAddPanelContext)

    const {updatePerformances, setUpdatePerformances} = useContext(UpdatePerformancesContext)

    const [user, setUser] = useState("")
    const [nbr_appels, setNbrAppels] = useState(0)
    const [nbr_appels_15s, setNbrAppels15s] = useState(0)
    const [nbr_appels_4m, setNbrAppels4m] = useState(0)
    const [connected_hours, setConnectedHours] = useState(8)
    const [callback, setCallback] = useState(0)
    const [dmt, setDmt] = useState(0)
    const [date, setDate] = useState(getYesterdayDate())

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        async function GetAllUsers() {
            try{
                const token = localStorage.getItem("token")
                const response = await fetch("https://wave-support.com/api/getusers", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`
                    }
                })
                if(response.status === 200){
                    const responseData = await response.json()
                    setAllUsers(sortUsersByFirstName(responseData))
                }
            }
            catch(err){
                console.log(err)
            }
        }
        GetAllUsers()
    }, [])

    async function AddPerformance() {
        try{
            const token = localStorage.getItem("token")
            const result = document.getElementById("message")
            result.textContent = ""
            setLoading(true)
            const response = await fetch("https://wave-support.com/api/addperformance", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    user: user,
                    nbr_appels: nbr_appels,
                    nbr_appels_15s: nbr_appels_15s,
                    nbr_appels_4m: nbr_appels_4m,
                    connected_hours: connected_hours,
                    callback: callback,
                    dmt: dmt,
                    ath: Math.round(nbr_appels / connected_hours),
                    date: date
                })
            })
            const responseData = await response.json()
            result.textContent = responseData.message
            setLoading(false)
            if(response.status === 200){
                setUpdatePerformances(!updatePerformances)
            }
        }
        catch(err){
            console.log(err)
        }
    }

    function sortUsersByFirstName(users) {
        return users.sort((a, b) => {
            const prenomA = a.prenom.toLowerCase();
            const prenomB = b.prenom.toLowerCase();
    
            if (prenomA < prenomB) {
                return -1;
            }
            if (prenomA > prenomB) {
                return 1;
            }
            return 0;
        });
    }

    function getYesterdayDate() {
        const today = new Date();
        today.setDate(today.getDate() - 1);

        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
        const day = String(today.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    function getTodayDate() {
        const today = new Date();

        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
        const day = String(today.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    function Effacer(){
        setUser("");
        setConnectedHours(8);
        setNbrAppels(0);
        setDmt(0)
        setNbrAppels15s(0);
        setNbrAppels4m(0);
        setCallback(0)
    }

    return(
        <div className="performance_add_panel">
            <div className="performance_add_panel__head">
                <div className="performance_add_panel__head__logo">
                    <i class="fa-solid fa-chart-pie"></i>
                </div>
                <div className="performance_add_panel__head__title">
                    Ajouter une performance
                </div>
            </div>
            <div className="performance_add_panel__form">
                <div className="performance_add_panel__form__reps">
                    <div className="performance_add_panel__form__reps__label">
                        Reps<span style={{color: "red"}}>*</span>
                    </div>
                    <div className="performance_add_panel__form__reps__input">
                        <select name="reps" id="reps" value={user} onChange={(e)=>{setUser(e.target.value)}}>
                            <option value=""></option>
                            {
                                allUsers.map(
                                    (user) => (
                                        <option value={user._id}>{user.prenom} {user.nom}</option>
                                    )
                                )
                            }
                        </select>
                    </div>
                </div>
                <div className="performance_add_panel__form__items">
                    <div className="performance_add_panel__form__item">
                        <div className="performance_add_panel__form__item__label">
                            Date<span style={{color: "red"}}>*</span>
                        </div>
                        <div className="performance_add_panel__form__item__input">
                            <input id="date" type="date" value={date} max={getTodayDate()} onChange={(e)=>{setDate(e.target.value)}}/>
                        </div>
                    </div>
                    <div className="performance_add_panel__form__item">
                        <div className="performance_add_panel__form__item__label">
                            Heures connectés<span style={{color: "red"}}>*</span>
                        </div>
                        <div className="performance_add_panel__form__item__input">
                            <input id="heures_connectes" type="number" value={connected_hours} onChange={(e)=>{setConnectedHours(e.target.value)}}/>
                        </div>
                    </div>
                </div>
                <div className="performance_add_panel__form__items">
                    <div className="performance_add_panel__form__item">
                        <div className="performance_add_panel__form__item__label">
                            Appels traités<span style={{color: "red"}}>*</span>
                        </div>
                        <div className="performance_add_panel__form__item__input">
                            <input id="nbr_appels" type="number" value={nbr_appels} onChange={(e)=>{setNbrAppels(e.target.value)}}/>
                        </div>
                    </div>
                    <div className="performance_add_panel__form__item">
                        <div className="performance_add_panel__form__item__label">
                            DMT<span style={{color: "red"}}>*</span>
                        </div>
                        <div className="performance_add_panel__form__item__input">
                            <input id="dmt" type="number" value={dmt} onChange={(e)=>{setDmt(e.target.value)}}/>
                        </div>
                    </div>
                </div>
                <div className="performance_add_panel__form__items">
                    <div className="performance_add_panel__form__item">
                        <div className="performance_add_panel__form__item__label">
                            {"Appels < 15 secondes"}<span style={{color: "red"}}>*</span>
                        </div>
                        <div className="performance_add_panel__form__item__input">
                            <input id="appels_15s" type="number" value={nbr_appels_15s} onChange={(e)=>{setNbrAppels15s(e.target.value)}}/>
                        </div>
                    </div>
                    <div className="performance_add_panel__form__item">
                        <div className="performance_add_panel__form__item__label">
                            {"Appels > 4 minutes"}<span style={{color: "red"}}>*</span>
                        </div>
                        <div className="performance_add_panel__form__item__input">
                            <input id="appels_4m" type="number" value={nbr_appels_4m} onChange={(e)=>{setNbrAppels4m(e.target.value)}}/>
                        </div>
                    </div>
                </div>
                <div className="performance_add_panel__form__items">
                    <div className="performance_add_panel__form__item">
                        
                    </div>
                    <div className="performance_add_panel__form__item">
                        <div className="performance_add_panel__form__item__label">
                            % de Rappels / Heure<span style={{color: "red"}}>*</span>
                        </div>
                        <div className="performance_add_panel__form__item__input">
                            <input id="cb" type="number" value={callback} onChange={(e)=>{setCallback(e.target.value)}}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="performance_add_panel__result" id="add_performance_result">
                {loading && <i class="fa-solid fa-spinner fa-spin"></i>}
                <div id="message"></div>
            </div>
            <div className="performance_add_panel__btns">
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className="performance_add_panel__btns__cancel" onClick={()=>{setDisplayPerformanceAddPanel(false)}}>
                        Fermer
                    </div>
                    <div className="performance_add_panel__btns__cancel" style={{marginLeft: "10px"}} onClick={()=>{Effacer()}}>
                        Effacer
                    </div>
                </div>
                <div className="performance_add_panel__btns__save" onClick={()=>{AddPerformance()}}>
                    Ajouter
                </div>
            </div>
        </div>
    )
}

export default PerformanceAddPanel