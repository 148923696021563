import { createContext, useState } from "react";

export const SelectedDateContext = createContext()

function getYesterdayDate() {
    const today = new Date();
    today.setDate(today.getDate() - 1);

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
    const day = String(today.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export const SelectedDateContextProvider = ({children}) => {
    const [selectedDate, setSelectedDate] = useState(getYesterdayDate())
    return(
        <SelectedDateContext.Provider value={{selectedDate, setSelectedDate}}>
            {children}
        </SelectedDateContext.Provider>
    )
}