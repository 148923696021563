import { useContext, useEffect, useState } from "react";
import { DisplayUserAddPanelContext } from "../../utils/displayUserAddPanelContext";

function UserAddPanel() {
    const [allUsers, setAllUsers] = useState([]);

    const [prenom, setPrenom] = useState("");
    const [nom, setNom] = useState("");
    const [mail, setMail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)

    const {setDisplayUserAddPanel} = useContext(DisplayUserAddPanelContext)

    useEffect(() => {
        async function GetAllUsers() {
            try {
                const token = localStorage.getItem("token");
                setLoading2(true)
                const response = await fetch("https://wave-support.com/api/getusers", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`
                    }
                });
                setLoading2(false)
                if (response.status === 200) {
                    const responseData = await response.json();
                    setAllUsers(sortUsersByFirstName(responseData));
                }
            } catch (err) {
                setLoading2(false)
                console.log(err);
            }
        }
        GetAllUsers();
    }, []);

    async function AddUser() {
        // Réinitialiser les erreurs
        setError("");

        // Valider les champs
        if (!isValidName(prenom)) {
            setError("Prénom invalide. Il doit avoir plus de 1 caractère.");
            return;
        }
        if (!isValidName(nom)) {
            setError("Nom invalide. Il doit avoir plus de 1 caractère.");
            return;
        }
        if (!isValidEmail(mail)) {
            setError("Email invalide.");
            return;
        }
        if (!isValidPassword(password)) {
            setError("Mot de passe invalide. Il doit avoir au moins 6 caractères.");
            return;
        }

        try {
            const token = localStorage.getItem("token");
            setLoading(true)
            const response = await fetch("https://wave-support.com/api/adduserbygl", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    prenom,
                    nom,
                    mail,
                    password
                })
            });
            setLoading(false)
            if (response.status === 201) {
                const newUser = await response.json();
                setAllUsers(sortUsersByFirstName([...allUsers, newUser.user]));
                setPrenom("");
                setNom("");
                setMail("");
                setPassword("");
            } else {
                const errorData = await response.json();
                setError(errorData.message);
            }
        } catch (err) {
            setError("Une erreur s'est produite lors de l'ajout de l'utilisateur.");
            setLoading(false)
            console.log(err);
        }
    }

    function isValidName(name) {
        return name && name.length > 1;
    }

    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    function isValidPassword(password) {
        return password && password.length >= 6;
    }

    function sortUsersByFirstName(users) {
        return users.sort((a, b) => {
            const prenomA = a.prenom.toLowerCase();
            const prenomB = b.prenom.toLowerCase();

            if (prenomA < prenomB) {
                return -1;
            }
            if (prenomA > prenomB) {
                return 1;
            }
            return 0;
        });
    }

    return (
        <div className="useraddpanel">
            <div className="useraddpanel__form">
                <div className="useraddpanel__form__head">
                    Ajouter un membre
                </div>
                {error && <div className="error-message">{error}</div>}
                <div className="useraddpanel__form__container">
                    <input type="text" placeholder="Prenom" value={prenom} onChange={(e) => { setPrenom(e.target.value) }} />
                    <input type="text" placeholder="Nom" value={nom} onChange={(e) => { setNom(e.target.value) }} />
                    <input type="text" placeholder="E-Mail" value={mail} onChange={(e) => { setMail(e.target.value) }} />
                    <input type="text" placeholder="Mot de passe" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                    <div className="performance_add_panel__result" id="add_performance_result">
                        {loading && <i class="fa-solid fa-spinner fa-spin"></i>}
                        <div id="message"></div>
                    </div>
                </div>
                <div className="useraddpanel__form__btns">
                    <div className="useraddpanel__form__btns__item" onClick={()=>{setDisplayUserAddPanel(false)}}>
                        Fermer
                    </div>
                    <div className="useraddpanel__form__btns__item" onClick={() => { AddUser() }}>
                        Ajouter
                    </div>
                </div>
            </div>
            <div className="useraddpanel__members">
                <div className="useraddpanel__members__head">
                    Membres du groupe
                </div>
                <div className="useraddpanel__members__container">
                    {!loading2 && allUsers.length === 0 && <div style={{ fontSize: "20px", textAlign: "center", margin: "10px 0px", color: "gray" }}>
                        Rien à afficher
                    </div>}
                    {loading2 && <div style={{ fontSize: "20px", textAlign: "center", margin: "10px 0px", color: "gray" }}>
                        <i class="fa-solid fa-spinner fa-spin"></i>
                    </div>}
                    {
                        allUsers.map((user, index) => (
                            <div className="useraddpanel__members__container__item" key={index}>
                                {user.prenom} {user.nom}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default UserAddPanel;
