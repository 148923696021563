import { createContext, useState } from "react";

export const DisplayUserAddPanelContext = createContext()

export const DisplayUserAddPanelContextProvider = ({children}) => {
    const [displayUserAddPanel, setDisplayUserAddPanel] = useState(false)

    return(
        <DisplayUserAddPanelContext.Provider value={{displayUserAddPanel, setDisplayUserAddPanel}}>
            {children}
        </DisplayUserAddPanelContext.Provider>
    )
}
