import { useContext } from "react";
import PerformanceChart from "../performanceChart";
import PerformanceOKR from "../performanceOKR";
import { DisplayRepsViewPerformanceContext } from "../../utils/displayRepsViewPerformance";
import PerformancesPanel from "../othersPerformancesView";

function RepsViewPerformance() {
    const {setDisplayRepsViewPerformance } = useContext(DisplayRepsViewPerformanceContext);

    return (
        <div className="view_performance">
            <div className="view_performance__stats">
                <div className="view_performance__stats__diagram">
                    <PerformanceChart />
                </div>
                <PerformanceOKR />
            </div>
            <div className="view_performance__edit">
                <div className="view_performance__edit__reps_head">
                    Performances du groupe
                </div>
                <PerformancesPanel searchTerm={""}/>
            </div>
            <div className="view_performance__close" onClick={()=>{setDisplayRepsViewPerformance(false)}}><i class="fa-regular fa-circle-xmark"></i></div>
        </div>
    )
}

export default RepsViewPerformance;
