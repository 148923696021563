import { createContext, useContext, useEffect, useState } from "react";
import { DisplayViewMonthlyPerformanceContext } from "./displayViewMonthlyPerformance";

export const MonthlyPerformanceToSeeContext = createContext()

export const MonthlyPerformanceToSeeContextProvider = ({children}) => {
    const [monthlyPerformanceToSee, setMonthlyPerformanceToSee] = useState({
        user: {
            prenom: "",
            nom: localStorage.getItem("team")
        },
        performance: {
            avg_nbr_appels: 0,
            avg_nbr_appels_15s: 0,
            avg_nbr_appels_4m: 0,
            avg_connected_hours: 0,
            avg_talking_time: 0,
            avg_callback: 0,
            avg_dmt: 0,
            avg_ath: 0,
        }
    })

    const { displayViewMonthlyPerformance } = useContext(DisplayViewMonthlyPerformanceContext);

    useEffect(()=>{
        async function GetMonthlyPerformance(){
            try {
                const token = localStorage.getItem("token");
                const response = await fetch(`https://wave-support.com/api/getlastweekaverageperformance`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    const responseData = await response.json();
                    setMonthlyPerformanceToSee({
                        user: {
                            prenom: "",
                            nom: localStorage.getItem("team")
                        },
                        performance: {
                            avg_nbr_appels: responseData.nbr_appels,
                            avg_nbr_appels_15s: responseData.nbr_appels_15s,
                            avg_nbr_appels_4m: responseData.nbr_appels_4m,
                            avg_connected_hours: responseData.connected_hours,
                            avg_talking_time: responseData.talking_time,
                            avg_callback: responseData.callback,
                            avg_dmt: responseData.dmt,
                            avg_ath: responseData.ath,
                        }
                    })
                } else {
                    console.error('Failed to fetch performances:', response.status);
                }
            } catch (err) {
                console.log(err);
            }
        }

        GetMonthlyPerformance();
    }, [displayViewMonthlyPerformance])

    return(
        <MonthlyPerformanceToSeeContext.Provider value={{monthlyPerformanceToSee, setMonthlyPerformanceToSee}}>
            {children}
        </MonthlyPerformanceToSeeContext.Provider>
    )
}