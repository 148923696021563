import { createContext, useState } from "react";

export const DisplayImportViewContext = createContext()

export const DisplayImportViewContextProvider = ({children}) => {
    const [displayImportView, setDisplayImportView] = useState(false)
    return(
        <DisplayImportViewContext.Provider value={{displayImportView, setDisplayImportView}}>
            {children}
        </DisplayImportViewContext.Provider>
    )
}