import { useContext, useState } from "react";
import { DisplayParametresContext } from "../../utils/displayParametresContext";

function Parametres() {
    const { setDisplayParametres } = useContext(DisplayParametresContext);
    const [loading, setLoading] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [message, setMessage] = useState("");

    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        if (password.length < minLength) {
            return `Le mot de passe doit contenir au moins ${minLength} caractères.`;
        }
        if (!hasUpperCase) {
            return "Le mot de passe doit contenir au moins une lettre majuscule.";
        }
        if (!hasLowerCase) {
            return "Le mot de passe doit contenir au moins une lettre minuscule.";
        }
        if (!hasNumber) {
            return "Le mot de passe doit contenir au moins un chiffre.";
        }
        if (!hasSpecialChar) {
            return "Le mot de passe doit contenir au moins un caractère spécial.";
        }
        return null;
    };

    const handleChangePassword = async () => {
        setLoading(true);
        setMessage("");
        const validationError = validatePassword(newPassword);
        if (validationError) {
            setMessage(validationError);
            setLoading(false);
            return;
        }

        try {
            const token = localStorage.getItem("token");
            const response = await fetch('https://wave-support.com/api/changepassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ oldPassword, newPassword })
            });

            const responseData = await response.json();
            if (response.status === 200) {
                setMessage('Mot de passe mis à jour avec succès');
                setNewPassword("")
                setOldPassword("")
            } else {
                setMessage(responseData.message);
            }
        } catch (err) {
            console.error(err);
            setMessage('Erreur lors de la mise à jour du mot de passe');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="parametres">
            <div className="parametres__head">
                <div className="parametres__head__title">
                    Mes informations
                </div>
                <div className="parametres__head__close" onClick={() => { setDisplayParametres(false) }}>
                    <i className="fa-regular fa-circle-xmark"></i>
                </div>
            </div>
            <div className="parametres__infos">
                <div className="parametres__infos__item">
                    <div className="parametres__infos__item__title">Prenom et Nom :</div>
                    <div className="parametres__infos__item__content">{localStorage.getItem("username")}</div>
                </div>
                <div className="parametres__infos__item">
                    <div className="parametres__infos__item__title">Team :</div>
                    <div className="parametres__infos__item__content">{localStorage.getItem("team")}</div>
                </div>
                <div className="parametres__infos__item">
                    <div className="parametres__infos__item__title">Role :</div>
                    <div className="parametres__infos__item__content">{localStorage.getItem("role")}</div>
                </div>
            </div>
            <div className="parametres__password">
                <div className="parametres__password__head">
                    Modifier mon mot de passe
                </div>
                <div className="parametres__password__form">
                    <input type="text" placeholder="Mot de passe actuel" autoComplete="off" value={oldPassword} onChange={(e) => { setOldPassword(e.target.value) }} />
                    <input type="text" placeholder="Nouveau Mot de passe" autoComplete="off" value={newPassword} onChange={(e) => { setNewPassword(e.target.value) }} />
                    <div className="parametres__password__form__btns">
                        {loading && <div className="loading">
                            <i className="fa-solid fa-spinner fa-spin"></i>
                        </div>}
                        <div className="parametres__password__form__btns__item" onClick={handleChangePassword}>
                            Enregistrer
                        </div>
                    </div>
                </div>
                {message && <div className="parametres__password__message">
                    {message}
                </div>}
            </div>
        </div>
    );
}

export default Parametres;
