import React, { useContext } from "react";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { MonthlyPerformanceToSeeContext } from "../../utils/monthlyPerformanceToSee";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

function MonthlyPerformanceAreaChart() {
    const { monthlyPerformanceToSee } = useContext(MonthlyPerformanceToSeeContext);

    // Define perfect performance values
    const perfectPerformance = {
        nbr_appels: 240, // Number of calls considered perfect
        nbr_appels_15s: 1, // Calls < 15s percentage
        nbr_appels_4m: 3, // Calls > 4m percentage
        connected_hours: 8, // Connected hours considered perfect
        talking_time: 85, // Talking time percentage
        callback: 15, // Callback percentage
        dmt: 1.9, // DMT value
        ath: 30, // ATH value
    };

    // Handle case where monthlyPerformanceToSee might not be available
    if (!monthlyPerformanceToSee || !monthlyPerformanceToSee.performance) {
        return <div>Loading...</div>;
    }

    const data = {
        labels: [
            'Number of Calls', 'Calls < 15s', 'Calls > 4m',
            'Connected Hours', 'Talking Time', 'Callback', 'DMT', 'ATH'
        ],
        datasets: [
            {
                label: `${monthlyPerformanceToSee.user.prenom} ${monthlyPerformanceToSee.user.nom}`,
                data: [
                    monthlyPerformanceToSee.performance.avg_nbr_appels,
                    monthlyPerformanceToSee.performance.avg_nbr_appels_15s,
                    monthlyPerformanceToSee.performance.avg_nbr_appels_4m,
                    monthlyPerformanceToSee.performance.avg_connected_hours,
                    monthlyPerformanceToSee.performance.avg_talking_time,
                    monthlyPerformanceToSee.performance.avg_callback,
                    monthlyPerformanceToSee.performance.avg_dmt,
                    Math.round(monthlyPerformanceToSee.performance.avg_nbr_appels / monthlyPerformanceToSee.performance.avg_connected_hours) || monthlyPerformanceToSee.performance.avg_ath
                ],
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                fill: true,
            },
            {
                label: 'Perfect Performance',
                data: [
                    perfectPerformance.nbr_appels,
                    perfectPerformance.nbr_appels_15s,
                    perfectPerformance.nbr_appels_4m,
                    perfectPerformance.connected_hours,
                    perfectPerformance.talking_time,
                    perfectPerformance.callback,
                    perfectPerformance.dmt,
                    perfectPerformance.ath
                ],
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
                fill: true,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `Performances de ${monthlyPerformanceToSee.user.prenom || "la"} ${monthlyPerformanceToSee.user.nom} vs Performance idéale`,
            }
        },
        scales: {
            y: {
                beginAtZero: true,
            }
        }
    };

    return <Line data={data} options={options} />;
}

export default MonthlyPerformanceAreaChart;
