import { createContext, useState } from "react";

export const DisplayExportViewContext = createContext()

export const DisplayExportViewContextProvider = ({children}) => {
    const [displayExportView, setDisplayExportView] = useState(false)
    return(
        <DisplayExportViewContext.Provider value={{displayExportView, setDisplayExportView}}>
            {children}
        </DisplayExportViewContext.Provider>
    )
}