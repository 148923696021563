import { useContext, useEffect, useState } from "react";
import { PerformanceToSeeContext } from "../../utils/performanceToSee";
import PerformanceChart from "../performanceChart";
import PerformanceOKR from "../performanceOKR";
import { DisplayViewPerformanceContext } from "../../utils/displayViewPerformance";
import { UpdatePerformancesContext } from "../../utils/updatePerformances";
import { SelectedDateContext } from "../../utils/selectedDate";

function ViewPerformance() {
    const { performanceToSee, setPerformanceToSee } = useContext(PerformanceToSeeContext);
    const { setDisplayViewPerformance } = useContext(DisplayViewPerformanceContext);

    const [userPerformance, setUserPerformance] = useState(performanceToSee);

    const [nbr_appels, setNbrAppels] = useState(userPerformance.nbr_appels);
    const [nbr_appels_15s, setNbrAppels15s] = useState(userPerformance.nbr_appels_15s);
    const [nbr_appels_4m, setNbrAppels4m] = useState(userPerformance.nbr_appels_4m);
    const [connected_hours, setConnectedHours] = useState(userPerformance.connected_hours);
    const [callback, setCallback] = useState(userPerformance.callback);
    const [dmt, setDmt] = useState(userPerformance.dmt);

    const { selectedDate } = useContext(SelectedDateContext);
    const { updatePerformances, setUpdatePerformances } = useContext(UpdatePerformancesContext);

    const [loading, setLoading] = useState(false);

    async function DeletePerformance() {
        try {
            const token = localStorage.getItem("token");
            const result = document.getElementById("message");
            result.textContent = "";
            setLoading(true);
            const response = await fetch(`https://wave-support.com/api/deleteperformance/${performanceToSee._id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                }
            });
            const responseData = await response.json();
            result.textContent = responseData.message;
            setLoading(false);
            if (response.status === 200) {
                setUpdatePerformances(!updatePerformances);
                setDisplayViewPerformance(false)
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    async function UpdatePerformance() {
        try {
            const token = localStorage.getItem("token");
            const result = document.getElementById("message");
            result.textContent = "";
            setLoading(true);
            const response = await fetch(`https://wave-support.com/api/updateperformance/${performanceToSee._id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    nbr_appels: nbr_appels,
                    nbr_appels_15s: nbr_appels_15s,
                    nbr_appels_4m: nbr_appels_4m,
                    connected_hours: connected_hours,
                    callback: callback,
                    talking_time: parseInt((nbr_appels * dmt / 60) * 100 / connected_hours),
                    ath: Math.round(nbr_appels / connected_hours),
                    dmt: dmt,
                })
            });
            const responseData = await response.json();
            result.textContent = responseData.message;
            setLoading(false);
            if (response.status === 200) {
                setUpdatePerformances(!updatePerformances);
                console.log(responseData)
                setPerformanceToSee(responseData)
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    useEffect(() => {
        async function GetUserPerformanceByDate() {
            try {
                const token = localStorage.getItem("token");
                const response = await fetch(`https://wave-support.com/api/getuserperformancebydate?userId=${performanceToSee.user._id}&date=${selectedDate}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    const responseData = await response.json();
                    setUserPerformance(responseData);
                } else {
                    console.error('Failed to fetch performances:', response.status);
                }
            } catch (err) {
                console.log(err);
            }
        }
        GetUserPerformanceByDate();
    }, [selectedDate, updatePerformances, performanceToSee]);

    return (
        <div className="view_performance">
            <div className="view_performance__stats">
                <div className="view_performance__stats__diagram">
                    <PerformanceChart />
                </div>
                <PerformanceOKR />
            </div>
            <div className="view_performance__edit">
                <div className="view_performance__edit__head">
                    Modifier la performance
                </div>
                <div className="view_performance__edit__form">
                    <div className="performance_add_panel__form__items">
                        <div className="performance_add_panel__form__item">
                            <div className="performance_add_panel__form__item__label">
                                Appels traités<span style={{ color: "red" }}>*</span>
                            </div>
                            <div className="performance_add_panel__form__item__input">
                                <input id="nbr_appels" type="number" value={nbr_appels} onChange={(e) => { setNbrAppels(e.target.value) }} />
                            </div>
                        </div>
                        <div className="performance_add_panel__form__item">
                            <div className="performance_add_panel__form__item__label">
                                DMT<span style={{ color: "red" }}>*</span>
                            </div>
                            <div className="performance_add_panel__form__item__input">
                                <input id="dmt" type="number" value={dmt} onChange={(e) => { setDmt(e.target.value) }} />
                            </div>
                        </div>
                    </div>
                    <div className="performance_add_panel__form__items">
                        <div className="performance_add_panel__form__item">
                            <div className="performance_add_panel__form__item__label">
                                {"Appels < 15 s"}<span style={{ color: "red" }}>*</span>
                            </div>
                            <div className="performance_add_panel__form__item__input">
                                <input id="appels_15s" type="number" value={nbr_appels_15s} onChange={(e) => { setNbrAppels15s(e.target.value) }} />
                            </div>
                        </div>
                        <div className="performance_add_panel__form__item">
                            <div className="performance_add_panel__form__item__label">
                                {"Appels > 4 mn"}<span style={{ color: "red" }}>*</span>
                            </div>
                            <div className="performance_add_panel__form__item__input">
                                <input id="appels_4m" type="number" value={nbr_appels_4m} onChange={(e) => { setNbrAppels4m(e.target.value) }} />
                            </div>
                        </div>
                    </div>
                    <div className="performance_add_panel__form__items">
                        <div className="performance_add_panel__form__item">
                            <div className="performance_add_panel__form__item__label">
                                {"Heures connectés"}<span style={{ color: "red" }}>*</span>
                            </div>
                            <div className="performance_add_panel__form__item__input">
                                <input id="connected_hours" type="number" value={connected_hours} onChange={(e) => { setConnectedHours(e.target.value) }} />
                            </div>
                        </div>
                        <div className="performance_add_panel__form__item">
                            <div className="performance_add_panel__form__item__label">
                                {"% Callback"}<span style={{ color: "red" }}>*</span>
                            </div>
                            <div className="performance_add_panel__form__item__input">
                                <input id="callback" type="number" value={callback} onChange={(e) => { setCallback(e.target.value) }} />
                            </div>
                        </div>
                    </div>
                    <div className="performance_add_panel__result" id="add_performance_result">
                        {loading && <i className="fa-solid fa-spinner fa-spin"></i>}
                        <div id="message"></div>
                    </div>
                </div>
                <div className="view_performance__edit__btns">
                    <div className="view_performance__edit__btns__btn" onClick={() => { setDisplayViewPerformance(false) }}>
                        Fermer
                    </div>
                    <div className="view_performance__edit__btns__btn" style={{backgroundColor: "red"}} onClick={()=>{DeletePerformance()}}>Supprimer</div>
                    <div className="view_performance__edit__btns__btn" onClick={() => { UpdatePerformance() }}>
                        Enregistrer
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewPerformance;
