import { useContext, useEffect, useState } from "react";
import { SelectedDateContext } from "../../utils/selectedDate";
import { UpdatePerformancesContext } from "../../utils/updatePerformances";

function AverageRepsPerformances() {
    const { selectedDate } = useContext(SelectedDateContext);
    const { updatePerformances } = useContext(UpdatePerformancesContext);
    const [averageRepsPerformances, setAverageRepsPerformances] = useState({});

    useEffect(() => {
        async function GetAverageRepsPerformances() {
            try {
                const token = localStorage.getItem("token");
                const response = await fetch(`https://wave-support.com/api/getperformance?date=${selectedDate}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.status === 200 || response.status === 404) {
                    const responseData = await response.json();
                    setAverageRepsPerformances(responseData);
                } else {
                    console.error('Failed to fetch performances:', response.status);
                }
            } catch (err) {
                console.log(err);
            }
        }
        GetAverageRepsPerformances();
    }, [selectedDate, updatePerformances]);

    const calculatePercentage = (part, total) => total ? (part * 100 / total).toFixed(0) : 0;

    return (
        <div className="admin_dashboard__container__stats__okrs">
            <div className={`admin_dashboard__container__stats__okrs__stat ${averageRepsPerformances.nbr_appels >= 240 ? "stat__green" : "stat__red"}`}>
                <div className="admin_dashboard__container__stats__okrs__stat__head">
                    <div className="admin_dashboard__container__stats__okrs__stat__head__item">
                        Appels traités
                    </div>
                    <div className="admin_dashboard__container__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-phone"></i>
                    </div>
                </div>
                <div className="admin_dashboard__container__stats__okrs__stat__data">
                    {averageRepsPerformances.nbr_appels || 0} A
                </div>
            </div>
            <div className={`admin_dashboard__container__stats__okrs__stat ${averageRepsPerformances.connected_hours >= 8 ? "stat__green" : "stat__red"}`}>
                <div className="admin_dashboard__container__stats__okrs__stat__head">
                    <div className="admin_dashboard__container__stats__okrs__stat__head__item">
                        H.C
                    </div>
                    <div className="admin_dashboard__container__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-hourglass"></i>
                    </div>
                </div>
                <div className="admin_dashboard__container__stats__okrs__stat__data">
                    {averageRepsPerformances.connected_hours ? Number(averageRepsPerformances.connected_hours).toFixed(1) : 0} H
                </div>
            </div>
            <div className={`admin_dashboard__container__stats__okrs__stat ${averageRepsPerformances.ath >= 30 ? "stat__green" : "stat__red"}`}>
                <div className="admin_dashboard__container__stats__okrs__stat__head">
                    <div className="admin_dashboard__container__stats__okrs__stat__head__item">
                        ATH
                    </div>
                    <div className="admin_dashboard__container__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-percent"></i>
                    </div>
                </div>
                <div className="admin_dashboard__container__stats__okrs__stat__data">
                    {averageRepsPerformances.ath || 0} A / H
                </div>
            </div>
            <div className={`admin_dashboard__container__stats__okrs__stat ${averageRepsPerformances.dmt <= 1.9 ? "stat__green" : "stat__red"}`}>
                <div className="admin_dashboard__container__stats__okrs__stat__head">
                    <div className="admin_dashboard__container__stats__okrs__stat__head__item">
                        DMT
                    </div>
                    <div className="admin_dashboard__container__stats__okrs__stat__head__icon">
                        <i className="fa-regular fa-clock"></i>
                    </div>
                </div>
                <div className="admin_dashboard__container__stats__okrs__stat__data">
                    {averageRepsPerformances.dmt || 0} mn
                </div>
            </div>
            <div className={`admin_dashboard__container__stats__okrs__stat ${averageRepsPerformances.callback <= 15 ? "stat__green" : averageRepsPerformances.callback <= 17 ? "stat__yellow" : "stat__red"}`} style={{marginTop: "0px"}}>
                <div className="admin_dashboard__container__stats__okrs__stat__head">
                    <div className="admin_dashboard__container__stats__okrs__stat__head__item">
                        Callback
                    </div>
                    <div className="admin_dashboard__container__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-arrow-rotate-left"></i>
                    </div>
                </div>
                <div className="admin_dashboard__container__stats__okrs__stat__data">
                    {averageRepsPerformances.callback || 0} %
                </div>
            </div>
            <div className={`admin_dashboard__container__stats__okrs__stat ${averageRepsPerformances.talking_time >= 85 ? "stat__green" : averageRepsPerformances.talking_time >= 83 ? "stat__yellow" : "stat__red"}`} style={{marginTop: "0px"}}>
                <div className="admin_dashboard__container__stats__okrs__stat__head">
                    <div className="admin_dashboard__container__stats__okrs__stat__head__item">
                        T.T
                    </div>
                    <div className="admin_dashboard__container__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-walkie-talkie"></i>
                    </div>
                </div>
                <div className="admin_dashboard__container__stats__okrs__stat__data">
                    {averageRepsPerformances.talking_time || 0} %
                </div>
            </div>
            <div className={`admin_dashboard__container__stats__okrs__stat ${calculatePercentage(averageRepsPerformances.nbr_appels_4m, averageRepsPerformances.nbr_appels) <= 3 ? "stat__green" : "stat__red"}`} style={{marginTop: "0px"}}>
                <div className="admin_dashboard__container__stats__okrs__stat__head">
                    <div className="admin_dashboard__container__stats__okrs__stat__head__item">
                        {"Appels > 4 mn"}
                    </div>
                    <div className="admin_dashboard__container__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-phone-volume"></i>
                    </div>
                </div>
                <div className="admin_dashboard__container__stats__okrs__stat__data">
                    {calculatePercentage(averageRepsPerformances.nbr_appels_4m, averageRepsPerformances.nbr_appels)} %
                </div>
            </div>
            <div className={`admin_dashboard__container__stats__okrs__stat ${calculatePercentage(averageRepsPerformances.nbr_appels_15s, averageRepsPerformances.nbr_appels) <= 1 ? "stat__green" : "stat__red"}`} style={{marginTop: "0px"}}>
                <div className="admin_dashboard__container__stats__okrs__stat__head">
                    <div className="admin_dashboard__container__stats__okrs__stat__head__item">
                        {"Appels < 15 s"}
                    </div>
                    <div className="admin_dashboard__container__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-phone-slash"></i>
                    </div>
                </div>
                <div className="admin_dashboard__container__stats__okrs__stat__data">
                    {calculatePercentage(averageRepsPerformances.nbr_appels_15s, averageRepsPerformances.nbr_appels)} %
                </div>
            </div>
        </div>
    )
}

export default AverageRepsPerformances;
