import { useContext, useEffect, useState } from "react";
import { UpdatePerformancesContext } from "../../utils/updatePerformances";
import { SelectedDateContext } from "../../utils/selectedDate";
import { DisplayViewPerformanceContext } from "../../utils/displayViewPerformance";
import { PerformanceToSeeContext } from "../../utils/performanceToSee";
import { DisplayRepsViewPerformanceContext } from "../../utils/displayRepsViewPerformance";

function PerformancesPanel({ searchTerm }) {
    const [performances, setPerformances] = useState([]);

    const { updatePerformances } = useContext(UpdatePerformancesContext);
    const { selectedDate } = useContext(SelectedDateContext);

    const { setDisplayViewPerformance } = useContext(DisplayViewPerformanceContext);
    const { setDisplayRepsViewPerformance } = useContext(DisplayRepsViewPerformanceContext);

    const { setPerformanceToSee } = useContext(PerformanceToSeeContext);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function GetPerformances() {
            try {
                const token = localStorage.getItem("token");
                setPerformances([]);
                setLoading(true);
                const response = await fetch(`https://wave-support.com/api/getperformances?date=${selectedDate}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`
                    }
                });
                setLoading(false);
                if (response.status === 200) {
                    const responseData = await response.json();
                    // Trier les performances par nombre d'appels traités
                    responseData.sort((a, b) => b.nbr_appels - a.nbr_appels);
                    setPerformances(responseData);
                } else {
                    console.error('Failed to fetch performances:', response.status);
                }
            } catch (err) {
                console.log(err);
            }
        }
        GetPerformances();
    }, [updatePerformances, selectedDate]);

    const filteredPerformances = performances.filter(performance =>
        `${performance.user.prenom} ${performance.user.nom}`.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="admin_dashboard__container__reps_list__container" style={{ height: "82%" }}>
            {loading && <div style={{ fontSize: "20px", textAlign: "center", margin: "10px 0px" }}>
                <i className="fa-solid fa-spinner fa-spin"></i>
            </div>}
            {!loading && filteredPerformances.length === 0 && <div style={{ fontSize: "20px", textAlign: "center", margin: "15px 0px", color: "gray" }}>
                Rien à afficher
            </div>}
            {filteredPerformances.map((performance) => (
                <div key={performance._id} className="admin_dashboard__container__reps_list__container__performance" onClick={() => { setPerformanceToSee(performance); setDisplayViewPerformance(true); setDisplayRepsViewPerformance(true) }}>
                    <div className="admin_dashboard__container__reps_list__container__performance__profil">
                        {performance.user.prenom.charAt(0)}{performance.user.nom.charAt(0)}
                    </div>
                    <div className="admin_dashboard__container__reps_list__container__performance__stats">
                        <div className="admin_dashboard__container__reps_list__container__performance__stats__stats1">
                            <div className="admin_dashboard__container__reps_list__container__performance__stats__stats1__name">
                                {performance.user.prenom} {performance.user.nom}
                            </div>
                            <div className="admin_dashboard__container__reps_list__container__performance__stats__stats1__calls">
                                {performance.nbr_appels} A
                                {performance.nbr_appels >= 240 ? (
                                    <i className="fa-solid fa-sort-up" style={{ color: "#63E6BE", marginLeft: "5px" }}></i>
                                ) : (
                                    <i className="fa-solid fa-sort-down" style={{ color: "#e82711", marginLeft: "5px" }}></i>
                                )}
                            </div>
                        </div>
                        <div className="admin_dashboard__container__reps_list__container__performance__stats__stats2">
                            <div>
                                T.T : {performance.talking_time}%
                                {performance.talking_time >= 85 ? (
                                    <i className="fa-solid fa-sort-up" style={{ color: "#63E6BE", marginLeft: "5px" }}></i>
                                ) : performance.talking_time >= 83 ? (
                                    <i className="fa-solid fa-minus" style={{ color: "rgb(220, 220, 57)", marginLeft: "5px" }}></i>
                                ) : (
                                    <i className="fa-solid fa-sort-down" style={{ color: "#e82711", marginLeft: "5px" }}></i>
                                )}
                            </div>
                            <div>
                                CB : {Math.round(performance.callback)}%
                                {Math.round(performance.callback) <= 15 ? (
                                    <i className="fa-solid fa-sort-up" style={{ color: "#63E6BE", marginLeft: "5px" }}></i>
                                ) : Math.round(performance.callback) <= 17 ? (
                                    <i className="fa-solid fa-minus" style={{ color: "rgb(220, 220, 57)", marginLeft: "5px" }}></i>
                                ) : (
                                    <i className="fa-solid fa-sort-down" style={{ color: "#e82711", marginLeft: "5px" }}></i>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default PerformancesPanel;
