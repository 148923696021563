import { useContext } from "react";
import { MonthlyPerformanceToSeeContext } from "../../utils/monthlyPerformanceToSee";

function MonthlyPerformanceOKR() {
    const { monthlyPerformanceToSee } = useContext(MonthlyPerformanceToSeeContext);

    // Vérifier si les données sont disponibles
    if (!monthlyPerformanceToSee || !monthlyPerformanceToSee.performance) {
        return <div>Loading...</div>;
    }

    // Définir les seuils de performance
    const thresholds = {
        nbr_appels: 240,
        connected_hours: 8,
        ath: 30,
        dmt: 1.9,
        callback: 15,
        talking_time: 85,
        nbr_appels_4m: 3,
        nbr_appels_15s: 1,
    };

    // Fonction utilitaire pour déterminer si la performance est au-dessus du seuil
    const checkThreshold = (value, threshold, isPercentage = false) => {
        if (isPercentage) {
            return value <= threshold;
        }
        return value >= threshold;
    };

    return (
        <div className="view_performance__stats__okrs">
            <div className={`view_performance__stats__okrs__stat ${checkThreshold(monthlyPerformanceToSee.performance.avg_nbr_appels, thresholds.nbr_appels) ? "stat__green" : "stat__red"}`}>
                <div className="view_performance__stats__okrs__stat__head">
                    <div className="view_performance__stats__okrs__stat__head__item">
                        Appels traités
                    </div>
                    <div className="view_performance__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-phone"></i>
                    </div>
                </div>
                <div className="view_performance__stats__okrs__stat__data">{monthlyPerformanceToSee.performance.avg_nbr_appels || 0} A</div>
            </div>
            <div className={`view_performance__stats__okrs__stat ${checkThreshold(monthlyPerformanceToSee.performance.avg_connected_hours, thresholds.connected_hours) ? "stat__green" : "stat__red"}`}>
                <div className="view_performance__stats__okrs__stat__head">
                    <div className="view_performance__stats__okrs__stat__head__item">
                        {"H.C"}
                    </div>
                    <div className="view_performance__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-hourglass"></i>
                    </div>
                </div>
                <div className="view_performance__stats__okrs__stat__data">{monthlyPerformanceToSee.performance.avg_connected_hours || 0} H</div>
            </div>
            <div className={`view_performance__stats__okrs__stat ${checkThreshold(Math.round(monthlyPerformanceToSee.performance.avg_nbr_appels / monthlyPerformanceToSee.performance.avg_connected_hours), thresholds.ath) ? "stat__green" : "stat__red"}`}>
                <div className="view_performance__stats__okrs__stat__head">
                    <div className="view_performance__stats__okrs__stat__head__item">
                        ATH
                    </div>
                    <div className="view_performance__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-percent"></i>
                    </div>
                </div>
                <div className="view_performance__stats__okrs__stat__data">{Math.round(monthlyPerformanceToSee.performance.avg_nbr_appels / monthlyPerformanceToSee.performance.avg_connected_hours) || 0} A / H</div>
            </div>
            <div className={`view_performance__stats__okrs__stat ${checkThreshold(monthlyPerformanceToSee.performance.avg_dmt, thresholds.dmt, true) ? "stat__green" : "stat__red"}`}>
                <div className="view_performance__stats__okrs__stat__head">
                    <div className="view_performance__stats__okrs__stat__head__item">
                        DMT
                    </div>
                    <div className="view_performance__stats__okrs__stat__head__icon">
                        <i className="fa-regular fa-clock"></i>
                    </div>
                </div>
                <div className="view_performance__stats__okrs__stat__data">{monthlyPerformanceToSee.performance.avg_dmt || 0} mn</div>
            </div>
            <div className={`view_performance__stats__okrs__stat ${checkThreshold(monthlyPerformanceToSee.performance.avg_callback, thresholds.callback, true) ? "stat__green" : "stat__red"}`}>
                <div className="view_performance__stats__okrs__stat__head">
                    <div className="view_performance__stats__okrs__stat__head__item">
                        Callback
                    </div>
                    <div className="view_performance__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-arrow-rotate-left"></i>
                    </div>
                </div>
                <div className="view_performance__stats__okrs__stat__data">{monthlyPerformanceToSee.performance.avg_callback || 0} %</div>
            </div>
            <div className={`view_performance__stats__okrs__stat ${checkThreshold(monthlyPerformanceToSee.performance.avg_talking_time, thresholds.talking_time) ? "stat__green" : "stat__red"}`}>
                <div className="view_performance__stats__okrs__stat__head">
                    <div className="view_performance__stats__okrs__stat__head__item">
                        {"T.T"}
                    </div>
                    <div className="view_performance__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-walkie-talkie"></i>
                    </div>
                </div>
                <div className="view_performance__stats__okrs__stat__data">{monthlyPerformanceToSee.performance.avg_talking_time || 0} %</div>
            </div>
            <div className={`view_performance__stats__okrs__stat ${checkThreshold(parseInt(monthlyPerformanceToSee.performance.avg_nbr_appels_4m * 100 / monthlyPerformanceToSee.performance.avg_nbr_appels), thresholds.nbr_appels_4m, true) ? "stat__green" : "stat__red"}`}>
                <div className="view_performance__stats__okrs__stat__head">
                    <div className="view_performance__stats__okrs__stat__head__item">
                        {"Appels > 4 mn"}
                    </div>
                    <div className="view_performance__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-phone-volume"></i>
                    </div>
                </div>
                <div className="view_performance__stats__okrs__stat__data">{parseInt(monthlyPerformanceToSee.performance.avg_nbr_appels_4m * 100 / monthlyPerformanceToSee.performance.avg_nbr_appels) || 0} %</div>
            </div>
            <div className={`view_performance__stats__okrs__stat ${checkThreshold(parseInt(monthlyPerformanceToSee.performance.avg_nbr_appels_15s * 100 / monthlyPerformanceToSee.performance.avg_nbr_appels), thresholds.nbr_appels_15s, true) ? "stat__green" : "stat__red"}`}>
                <div className="view_performance__stats__okrs__stat__head">
                    <div className="view_performance__stats__okrs__stat__head__item">
                        {"Appels < 15 s"}
                    </div>
                    <div className="view_performance__stats__okrs__stat__head__icon">
                        <i className="fa-solid fa-phone-slash"></i>
                    </div>
                </div>
                <div className="view_performance__stats__okrs__stat__data">{parseInt(monthlyPerformanceToSee.performance.avg_nbr_appels_15s * 100 / monthlyPerformanceToSee.performance.avg_nbr_appels) || 0} %</div>
            </div>
        </div>
    );
}

export default MonthlyPerformanceOKR;
