import { useState } from "react";
import { useNavigate } from "react-router-dom";

function App() {
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  async function Login() {
    try {
      const response_div = document.getElementById("response");
      response_div.textContent = "";
      setLoading(true);
      const response = await fetch("https://wave-support.com/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mail: mail,
          password: password,
        }),
      });
      setLoading(false);
      if (response.status === 200) {
        const responseData = await response.json();
        response_div.textContent = responseData.message;
        localStorage.setItem("token", responseData.token);
        localStorage.setItem("username", responseData.username);
        localStorage.setItem("team", responseData.team);
        localStorage.setItem("role", responseData.role);
        if (responseData.role === "Group Lead") {
          navigate("/grouplead-dashboard");
        }
        if (responseData.role === "Customer Support Representative") {
          navigate("/reps-dashboard");
        }
      } else {
        const responseData = await response.json();
        response_div.style.color = "red";
        response_div.textContent = responseData.message;
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      Login();
    }
  }

  return (
    <div className="login_page">
      <div className="login_page__content">
        <div className="login_page__content__head">
          <div className="login_page__content__head__logo">
            <img src="/images/wave-logo.png" alt="" width={150} />
          </div>
          <div className="login_page__content__head__text">
            <span style={{ fontWeight: "lighter", color: "#f67a00" }}>Evaluation</span> et
            <br />
            Suivi des <span style={{ fontWeight: "lighter", color: "#1dc4ff" }}>Performances</span>
          </div>
        </div>
        <div className="login_page__content__form">
          <input
            type="mail"
            placeholder="Adresse E-Mail Professionnel"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
            onKeyDown={handleKeyDown} // Ajout de l'événement onKeyDown
          />
          <input
            type="password"
            placeholder="Mot De Passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown} // Ajout de l'événement onKeyDown
          />
          <div className="login_page__content__form__btns">
            <a href="/">Mot de passe oublié ?</a>
            <div className="login_page__content__form__btns__submit" onClick={Login}>
              <i className="fa-solid fa-arrow-right"></i>
            </div>
          </div>
        </div>
        <div className="login_page__response" id="response">
          {loading && <i className="fa-solid fa-spinner fa-spin"></i>}
        </div>
        <div className="login_page__content__foot">
          &copy; Copyright 2024, Wave Sénégal - Saliou Diop, All Rights Reserved
        </div>
      </div>
      <div className="login_page__picture">
        <img src="/images/csr-2-rbg.png" alt="" width={100} />
      </div>
    </div>
  );
}

export default App;
