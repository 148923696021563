import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { SelectedDateContext } from "../../utils/selectedDate";
import { UpdatePerformancesContext } from "../../utils/updatePerformances";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

function AveragePerformancesChart() {
    const [averagePerformances, setAveragePerformances] = useState(null);
    const { selectedDate } = useContext(SelectedDateContext);
    const { updatePerformances } = useContext(UpdatePerformancesContext);

    const perfectPerformance = {
        nbr_appels: 240,
        nbr_appels_15s: 1,
        nbr_appels_4m: 3,
        connected_hours: 8,
        talking_time: 85,
        callback: 15,
        dmt: 1.9,
        ath: 30,
    };

    useEffect(() => {
        async function GetAveragePerformances() {
            try {
                const token = localStorage.getItem("token");
                const response = await fetch(`https://wave-support.com/api/getaverageperformances?date=${selectedDate}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    const responseData = await response.json();
                    setAveragePerformances(responseData);
                } else {
                    console.error('Failed to fetch average performances:', response.status);
                }
            } catch (err) {
                console.log(err);
            }
        }
        GetAveragePerformances();
    }, [selectedDate, updatePerformances]);

    if (!averagePerformances) {
        return <div>Loading...</div>;
    }

    const getColor = (value, threshold, isHigherBetter) => {
        if (isHigherBetter) {
            return value >= threshold ? 'rgba(75, 192, 192, 0.6)' : 'rgba(255, 99, 132, 0.6)';
        } else {
            if (value <= threshold[0]) return 'rgba(75, 192, 192, 0.6)';
            if (value <= threshold[1]) return 'rgba(255, 205, 86, 0.6)'; // Yellow
            return 'rgba(255, 99, 132, 0.6)'; // Red
        }
    };

    const data = {
        labels: [
            'Number of Calls', 'Calls < 15s', 'Calls > 4m', 
            'Connected Hours', 'Talking Time', 'Callback', 'DMT', 'ATH'
        ],
        datasets: [
            {
                label: 'Moyenne Performances Team',
                data: [
                    averagePerformances.nbr_appels,
                    averagePerformances.nbr_appels_15s,
                    averagePerformances.nbr_appels_4m,
                    averagePerformances.connected_hours,
                    averagePerformances.talking_time,
                    averagePerformances.callback,
                    averagePerformances.dmt,
                    averagePerformances.ath
                ],
                backgroundColor: [
                    getColor(averagePerformances.nbr_appels, perfectPerformance.nbr_appels, true),
                    getColor(averagePerformances.nbr_appels_15s, perfectPerformance.nbr_appels_15s, false),
                    getColor(averagePerformances.nbr_appels_4m, perfectPerformance.nbr_appels_4m, false),
                    getColor(averagePerformances.connected_hours, perfectPerformance.connected_hours, true),
                    getColor(averagePerformances.talking_time, [83, 85], true),
                    getColor(averagePerformances.callback, [15, 17], false),
                    getColor(averagePerformances.dmt, perfectPerformance.dmt, false),
                    getColor(averagePerformances.ath, perfectPerformance.ath, true)
                ],
                borderColor: [
                    getColor(averagePerformances.nbr_appels, perfectPerformance.nbr_appels, true),
                    getColor(averagePerformances.nbr_appels_15s, perfectPerformance.nbr_appels_15s, false),
                    getColor(averagePerformances.nbr_appels_4m, perfectPerformance.nbr_appels_4m, false),
                    getColor(averagePerformances.connected_hours, perfectPerformance.connected_hours, true),
                    getColor(averagePerformances.talking_time, [83, 85], true),
                    getColor(averagePerformances.callback, [15, 17], false),
                    getColor(averagePerformances.dmt, perfectPerformance.dmt, false),
                    getColor(averagePerformances.ath, perfectPerformance.ath, true)
                ],
                borderWidth: 1,
            },
            {
                label: 'Moyenne Performance attendue',
                data: [
                    perfectPerformance.nbr_appels,
                    perfectPerformance.nbr_appels_15s,
                    perfectPerformance.nbr_appels_4m,
                    perfectPerformance.connected_hours,
                    perfectPerformance.talking_time,
                    perfectPerformance.callback,
                    perfectPerformance.dmt,
                    perfectPerformance.ath
                ],
                backgroundColor: 'rgba(239, 241, 248, 0.3)', // 30% opacity
                borderColor: 'rgba(239, 241, 248, 0.6)', // 60% opacity
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Moyenne Performances Team vs Performance attendue',
            }
        },
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    return <Bar data={data} options={options} />;
}

export default AveragePerformancesChart;
