import { Children, createContext, useState } from "react";

export const DisplayPerformanceAddPanelContext = createContext()

export const DisplayPerformanceAddPanelContextProvider = ({children}) => {
    const [displayPerformanceAddPanel, setDisplayPerformanceAddPanel] = useState(false)

    return(
        <DisplayPerformanceAddPanelContext.Provider value={{displayPerformanceAddPanel, setDisplayPerformanceAddPanel}}>
            {children}
        </DisplayPerformanceAddPanelContext.Provider>
    )
}
